import React, { useEffect, useState } from "react";
import GaugeComponent from "react-gauge-component";
import style from "./GuageChart.module.scss";

const GuageChartSmart = ({
  value,
  targets,
  hit,
  type,
  setPercent,
}: {
  value: number;
  targets: number;
  hit: any[];
  type: string;
  setPercent: (val: number) => void;
}) => {
  const [targetArr, setTargetArr] = useState<any>([0, 50, 100]);
  const [load, setLoad] = useState<boolean>(false);
  const [percentage, setPercentage] = useState<number>(0);

  useEffect(() => {
    const onesCount = countOnes(hit); // Call the function
    console.log(onesCount, hit);
    if (targets == 1) {
      setTargetArr([0, 100]);
      if (onesCount == 1) {
        setPercentage(100);
      }
    }
    if (targets == 2) {
      setTargetArr([0, 50, 100]);
      if (onesCount == 1) {
        setPercentage(50);
      }
      if (onesCount == 2) {
        setPercentage(100);
      }
    }
    if (targets == 3) {
      setTargetArr([0, 33, 66, 100]);
      if (onesCount == 1) {
        setPercentage(50);
      }
      if (onesCount == 2) {
        setPercentage(75);
      }
      if (onesCount == 3) {
        setPercentage(100);
      }
    }
    if (targets == 4) {
      setTargetArr([0, 25, 50, 75, 100]);
      if (onesCount == 1) {
        setPercentage(25);
      }
      if (onesCount == 2) {
        setPercentage(50);
      }
      if (onesCount == 3) {
        setPercentage(75);
      }
      if (onesCount == 4) {
        setPercentage(100);
      }
    }
    setLoad(true);
    // setTimeout(() => {
    // }, 10);
  }, [targets]);
  console.log(hit);

  useEffect(() => {
    setPercent(percentage);
  }, [percentage]);

  const countOnes = (arr: string[]): number =>
    arr.filter((x) => x === "1").length;

  return (
    <div className={style.guageWrapper}>
      {load && (
        <GaugeComponent
          type="semicircle"
          id="abc"
          arc={{
            width: 0.03,

            padding: 0.02,
            cornerRadius: 0,
            subArcs: [
              // {
              //   limit: 2.5,
              //   color: 'rgba(185, 229, 73, 1)',
              //   showTick: true
              // },
              {
                limit: 25,
                color: "rgba(185, 229, 73, 1)",
                showTick: true,

                // tooltip : {
                //   text : "sss"
                // }
              },
              // {
              //   limit: 10,
              //   color: 'rgba(255, 255, 255, 1)',
              //   showTick: true
              // },
              // {
              //   limit: 15,
              //   color: 'rgba(255, 255, 255, 1)',
              //   showTick: true
              // },

              // {
              //   limit: 20,
              //   color: 'rgba(255, 255, 255, 1)',
              //   showTick: true
              // },
              // {
              //   limit: 25,
              //   color: 'rgba(255, 255, 255, 1)',
              //   showTick: true
              // },
              // {
              //   limit: 30,
              //   color: 'rgba(255, 255, 255, 1)',
              //   showTick: true
              // },
              // {
              //   limit: 35,
              //   color: 'rgba(255, 255, 255, 1)',
              //   showTick: true
              // },
              // {
              //   limit: 40,
              //   color: 'rgba(255, 255, 255, 1)',
              //   showTick: true
              // },
              // {
              //   limit: 45,
              //   color: 'rgba(255, 255, 255, 1)',
              //   showTick: true
              // },
              {
                limit: 50,
                color: "rgba(255, 255, 255, 1)",
                showTick: true,
              },
              // {
              //   limit: 55,
              //   color: 'rgba(240, 110, 34, 1)',
              //   showTick: true
              // },
              // {
              //   limit: 60,
              //   color: 'rgba(240, 110, 34, 1)',
              //   showTick: true
              // },
              // {
              //   limit: 65,
              //   color: 'rgba(240, 110, 34, 1)',
              //   showTick: true
              // },
              // {
              //   limit: 70,
              //   color: 'rgba(240, 110, 34, 1)',
              //   showTick: true
              // },
              {
                limit: 75,
                color: "rgba(240, 110, 34, 1)",
                showTick: true,
              },
              // {
              //   limit: 80,
              //   color: 'rgba(212, 23, 31, 1)',
              //   showTick: true
              // },
              // {
              //   limit: 85,
              //   color: 'rgba(212, 23, 31, 1)',
              //   showTick: true
              // },
              // {
              //   limit: 90,
              //   color: 'rgba(212, 23, 31, 1)',
              //   showTick: true
              // },
              // {
              //   limit: 95,
              //   color: 'rgba(212, 23, 31, 1)',
              //   showTick: true
              // },
              {
                limit: 100,
                color: "rgba(212, 23, 31, 1)",
                showTick: true,
              },
              { color: "#EA4228" },
            ],
          }}
          pointer={{
            color: "rgba(234, 89, 84, 1)",
            length: 0.7,
            width: 2,
            elastic: true,
          }}
          labels={{
            valueLabel: {
              hide: true,
            },

            // valueLabel: { formatTextValue: value => value + 'ºC' },
            tickLabels: {
              type: "inner",
              defaultTickLineConfig: {
                hide: true,
              },
              defaultTickValueConfig: {
                formatTextValue: (v) => {
                  return targetArr.includes(v) ? v : ".";
                },
                style: {
                  fontSize: 10,
                  fontFamily: "Poppins-Medium",
                  color: "#fff",
                  fill: "#fff",
                },
              },
              ticks: [
                { value: 2.5 },
                { value: 5 },
                { value: 10 },
                { value: 15 },
                { value: 20 },
                { value: 25 },
                { value: 30 },
                { value: 35 },
                { value: 40 },
                { value: 45 },
                { value: 50 },
                { value: 55 },
                { value: 60 },
                { value: 65 },
                { value: 70 },
                { value: 75 },
                { value: 80 },
                { value: 85 },
                { value: 90 },
                { value: 95 },
              ],
            },
          }}
          value={percentage}
          minValue={0}
          maxValue={100}
        />
      )}

      {type == "Forex market" ? (
        <>
          {load && (
            <>
              {targets > 3 && (
                <div className={`${style.guageLabel} ${style.bz}`}>0PIP</div>
              )}
              {targets > 2 && (
                <div className={`${style.guageLabel} ${style.hodel}`}>1PIP</div>
              )}
              {targets > 1 && (
                <div className={`${style.guageLabel} ${style.tp}`}>2PIP</div>
              )}
              {targets > 0 && (
                <div className={`${style.guageLabel} ${style.dangerRed}`}>
                  3PIP
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {load && (
            <>
              {targets > 3 && (
                <div className={`${style.guageLabel} ${style.bz}`}>TP1</div>
              )}
              {targets > 2 && (
                <div className={`${style.guageLabel} ${style.hodel}`}>TP2</div>
              )}
              {targets > 1 && (
                <div className={`${style.guageLabel} ${style.tp}`}>TP3</div>
              )}
              {targets > 0 && (
                <div className={`${style.guageLabel} ${style.dangerRed}`}>
                  TP4
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default GuageChartSmart;
