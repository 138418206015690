// import { useState, useEffect, RefObject } from "react";

// export const useVisibility = (
//   nodeRef: RefObject<HTMLElement>,
//   threshold = 0.2,
//   rootMargin = "0px"
// ) => {
//   const [visible, setVisible] = useState(false);
//   useEffect(() => {
//     let observer: IntersectionObserver;
//     const node = nodeRef.current;
//     if (!node) return () => {};
//     if (window.IntersectionObserver) {
//       observer = new IntersectionObserver(
//         ([entry]) => {
//           if (entry.isIntersecting) {
//             try {
//               setVisible(true);
//             } catch {} // eslint-disable-line no-empty
//             // observer.unobserve(node);
//           } else {
//             try {
//               setVisible(false);
//             } catch {} // eslint-disable-line no-empty
//           }
//         },
//         {
//           threshold,
//           rootMargin,
//         }
//       );

//       observer.observe(node);
//     } else {
//       setVisible(true);
//     }
//     return () => {
//       if (observer && observer.unobserve) {
//         observer.unobserve(node);
//       }
//     };
//   }, [nodeRef, threshold, rootMargin]);

//   return visible;
// };

import { useState, useEffect, RefObject } from "react";

export const useVisibility = (
  nodeRef: RefObject<HTMLElement>,
  threshold = 0,
  rootMargin = "100px"
) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const node = nodeRef.current;
    if (!node) return;

    let observer = new IntersectionObserver(
      ([entry]) => {
        console.log(
          "Observer triggered:",
          entry.isIntersecting,
          entry.boundingClientRect
        );
        setVisible(entry.isIntersecting);
      },
      {
        root: document.querySelector(".container"), // Make sure it's the scrolling div
        rootMargin,
        threshold,
      }
    );

    observer.observe(node);
    return () => observer.disconnect(); // Cleanup observer on unmount
  }, [nodeRef.current]); // ✅ Re-run when DOM updates

  return visible;
};
